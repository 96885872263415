<script setup>
import vr from './icon/vr.vue';


import { defineProps } from 'vue'

const props = defineProps({
  item: {
    type: String,
    default: '',
  },
})

</script>

<template>
<div>
<a class="card" :href="'/details.html?id='+props.item.id+'&name='+props.item.name">
    <img :src="props.item.thumb_src" alt="">
    <span class="left_text">{{props.item.name}}</span>
    <vr class="right_svg"/>
</a>
</div>
</template>

<style scoped>
.card {
    display: block;
    position: relative;
    text-decoration: none;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    aspect-ratio: 16 / 9;
    overflow: hidden;
}
.card img {
    display: block;
    position: absolute;
    object-fit: cover;/*图片不变形*/
    top: -30%;
    left: 0;
    width: 100%;
    height: auto;
}
.left_text, .right_svg {
    position: absolute;
    color: white;
    font-size: 1em;
    font-weight: 600;
    letter-spacing: 0.1em;/*字间距*/
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
}
.left_text {
    bottom: 0.4em;
    left: 0.4em;
    /* 超过一定长度的文字显示省略号 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 3em);
}
.right_svg {
    bottom: 0.4em;
    right: 0.4em;
    width: 1.5em;
}
.right_svg svg {
    width: 1em;
    height: 1em;
}
</style>
