<script setup>
import PanoramaCard from './components/PanoramaCard.vue'
import Nav from './components/nav.vue'
import Search from './components/search.vue'

import { ref, onBeforeMount, computed, watch } from 'vue'
import axios from 'axios'

const search_text = ref('')
const net_err = ref(false)
const PanoramaList = ref([])
const search_null = ref(false)

// const Page = ref(1)
// const PageSize = ref(12)//每页显示的数量
// const Page_num = computed(() =>{
//     return Math.ceil(PanoramaList.value.length / PageSize) //Math.ceil() 向上舍入
// })
//
// watch(search_text, (val) => {
//     Page.value = 1 //搜索时重置页数
// })


//搜索功能（计算属性）
const PanoramaListShow = computed(() => {
    const arr = PanoramaList.value.filter((p) => {
        return p.tag.indexOf(search_text.value) !== -1
    })

    // // 分页
    // arr.splice(Page.value * PageSize, arr.length - Page.value * PageSize)//截取数组
    return arr
})


// function GetPanoramaList() {
//     // 获取全景列表
//     axios.get('https://cn-cd-dx-tmp7.natfrp.cloud:60284/getPanoramaList') //手机端访问启用内网穿透
//     // axios.get('http://127.0.0.1:3000/getPanoramaList')
//         .then(function (response) {
//             console.log(response);
//             PanoramaList.value = response.data;
//         })
//         .catch(function (error) {
//             console.log(error);
//             net_err.value = true;
//         })
// }

//使用fetch获取getPanoramaList.json的数据赋值给PanoramaList
// function GetPanoramaList() {
//     fetch('./assets/getPanoramaList.json')//TODO
//         .then(response => response.json())
//         .then(data => {
//             PanoramaList.value = data
//         })
//         .catch(err => {
//             console.log(err)
//             net_err.value = true
//         })
// }

//使用axios获取https://api.panorama.mjiji.top/api/getPanoramaList的数据赋值给PanoramaList
function GetPanoramaList() {
    axios.get('https://api.panorama.mjiji.top/api/getPanoramaList')
        .then(function (response) {
            console.log(response);
            PanoramaList.value = response.data;
        })
        .catch(function (error) {
            console.log(error);
            net_err.value = true;
        })
}

watch(PanoramaListShow, (val) => {
    if (val.length === 0 && net_err.value === false) {
        search_null.value = true
    } else {
        search_null.value = false
    }
})

onBeforeMount(() => {
    GetPanoramaList()
})
</script>

<template>
    <Nav id="nav" :indexof="false"/>
    <div class="banner"></div>
    <Search class="search" v-model:stext="search_text" />
    <div class="PanoramaList">
        <PanoramaCard v-for="item in PanoramaListShow" :item="item" :key="item.id" />
        <!-- <PanoramaCard v-for="i in 12"/> -->
        <!-- <PanoramaCard id="//test" v-for="i in 9" :key="i"/> -->
        <img src="./assets/net_err.png" class="neterr" v-if="net_err">
        <img src="./assets/search_null.png" class="neterr" v-if="search_null">
    </div>

</template>

<style scoped>
* {
    user-select: none;
}
@media only screen and (max-width: 600px) {
    #nav {
        font-size: calc(3vw);
    }

    .banner {
        background-image: url('./assets/banner@x720.png');
        height: calc(100vw * 150 / 720);
    }

    .search {
        position: sticky;
        top: calc(14vw);
        z-index: 1;
    }

    .PanoramaList>div {
        width: 95%;
        font-size: calc(5vw);
        margin: 0 auto;
    }

    .neterr {
        margin: 0 auto;
        margin-top: 20%;
        width: 70%;
        opacity: 0.5;
    }
}

@media only screen and (min-width: 601px) {
    #nav {
        font-size: calc(0.2vw + 10px);
    }

    .banner {
        background-image: url('./assets/banner@x1920.png');
        height: calc(100vw * 150 / 1920);
    }

    .PanoramaList {
        width: calc(92vw + 30px);
        margin: 0 calc(8vw - 30px);
    }

    .PanoramaList>div {
        width: 23vw;
        font-size: calc(1.5vw);
        transform: 0.5s;
    }

    .neterr {
        margin: 0 auto;
        margin-top: 5%;
        width: 20%;
        opacity: 0.6;
    }
}

.banner {
    background-repeat: no-repeat;
    background-size: cover;
    /* 或者使用 contain */
    width: 100%;
}

.PanoramaList {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
    margin: 0 auto;
    padding-bottom: 20px;
}
</style>
