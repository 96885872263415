<script setup>
import { ref, defineModel } from 'vue'

const search_text = defineModel("stext", ref(''))
</script>

<template>
    <div class="search">
        <input type="text" v-model="search_text" placeholder="搜索">
    </div>
</template>

<style>
.search{
    width: 100%;
    text-align: center;
    padding: 10px 0;
    background-color: #ffffff;
}
/* 在搜索框前面加一个放大镜图标 */
.search input{
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 18px;
    background: url('../assets/search.png') no-repeat 10px center;
    background-size: 22px;
    padding-left: 44px;
}
@media only screen and (max-width: 600px){
    .search input{
    width: calc( 93% - 44px );
    }
}
@media only screen and (min-width: 601px){
    .search input{
        width: calc( 92vw - 24px );
    }
}
.search input:focus{
    outline: none;
    
}
</style>